<!--
 * @Author: your name
 * @Date: 2021-09-26 10:39:38
 * @LastEditTime: 2021-09-26 11:45:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \zhfd-web-sale\src\views\other\HelpCenter.vue
-->
<template>
  <div class="my-favors" >
    <div class="fixed_width">
      <breadCrumb />
      <div class="favors-contain">
        <nav-right-side class="nav" />
        <div class="favors-content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavRightSide from './NavRightSide'
import breadCrumb from '@/components/breadCrumb'
export default {
  components: {
    NavRightSide,
    breadCrumb
  }
}
</script>

<style lang="less" scoped>
.my-favors {
  width: 100%;
  background: #f3f4f6;
  .favors-contain {
    margin: 0 auto;
    padding-bottom: 40px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
  }
  .favors-content {
    width: 930px;
  }
}
</style>