// author:马云阳；description:众惠
<template>
  <section class="NavRightSide">
    <div class="nav_cont">
      <!-- nav -->
      <div v-for="(obj,i) in navList" :key="i" class="list_">
        <a
          :class="listId==obj.name&&obj.type!=1?'nav_cont_listAC':''"
          class="myAssets nav_list"
          @click="showNavlist(obj)"
        >
          <div :class="showright&&ids==obj.name?'nav_list_ac':''" class="nav_txt">{{ obj.navtitle }}</div>
          <!-- <img v-if="obj.type==1" :src="showright&&ids==obj.id?'" alt=""> -->
          <div v-if="obj.type==1" :class="showright&&ids==obj.name?'imgs_ac':''" class="imgs_">
            <span class="iconfont" style="font-size:10px;">&#xe679;</span>
          </div>
        </a>
        <div v-show="showindex==obj.name&&showright" class="list_Children">
          <a
            v-for="(conts,j) in obj.navChildren"
            :key="j"
            :class="listId==conts.name?'nav_cont_listAC':''"
            class="nav_cont_list"
            @click.stop="toPagebool==conts.name?'':toPage(conts)"
          >{{ conts.titles }}</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import RightBar from "./RightBar.vue";
import { mapGetters } from 'vuex'

export default {
  name: 'NavRightSide',
  components: {},
  props: {
    userinfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      showright: false, //默认收起导航
      showindex: '', //导航一级索引
      ids: null, //导航一级索引ID
      listId: null, //导航二级ID
      navList: [
        {
          navtitle: '常见问题',
          type: 2,
          id: 1,
          name: 'CommProblem'
        },
        {
          navtitle: '意见反馈',
          type: 2,
          id: 2,
          name: 'advice'
        }
      ],
      userinfos: {}, //用户信息
      mobelies: null,
      toPagebool: '' //当前页面路由
    }
  },
  computed: {
    ...mapGetters(['name', 'mobile'])
  },
  watch: {
    $route(to) {
      console.log(to) //到哪去

      this.toPagebool = to.name

      if (to.meta.chdr && to.meta.name && to.meta.type == 1) {
        this.ids = null
        this.listId = to.meta.name
        this.showright = false
        this.showindex = ''
      } else if (to.meta.name && to.meta.type == 1) {
        this.ids = null
        this.listId = to.meta.name
        this.showright = false
        this.showindex = ''
      } else if (
        to.meta.name &&
        to.meta.type == 2 &&
        to.meta.chdr &&
        to.meta.titles
      ) {
        this.showright = true
        this.ids = to.meta.titles
        this.listId = to.meta.name
        this.showindex = to.meta.titles
      } else if (to.meta.name && to.meta.type == 2 && to.meta.titles) {
        this.showright = true
        this.ids = to.meta.titles
        this.listId = to.meta.name
        this.showindex = to.meta.titles
      }
      // console.log('this.listId', this.listId)
    }
  },
  created() {
    this.userinfos =
      JSON.parse(window.localStorage.getItem('customerInfo')) || {}
    let str = this.userinfos.mobileTel.slice(0, 3)
    let str2 = this.userinfos.mobileTel.slice(-4)
    this.mobelies = str + '****' + str2

    // 左侧导航高亮
    this.listId =
      this.$route.meta.name || window.sessionStorage.getItem('activeLoad')
  },
  methods: {
    showNavlist(o) {
      console.log('ooo', o)
      // 保存左侧导航高亮
      window.sessionStorage.setItem('activeLoad', o.name)

      if (o.type == 1) {
        // if (this.showright) {
        //   this.showright = false;
        //   return;
        // } else {
        // }
        this.showright = true
        this.showindex = o.name
        this.ids = o.name
        if (o.name == this.toPagebool) {
          return
        } else {
          this.$router.push({
            name: o.name
          })
        }
      } else {
        this.ids = null
        this.listId = o.name
        if (o.name == this.toPagebool) {
          return
        } else {
          this.$router.push({
            name: o.name
          })
        }
      }
    },
    toPage(e) {
      console.log('eee', e)
      this.listId = e.name
      if (e.name) {
        this.$router.push({ name: e.name })
      } else if (e.path) {
        this.$router.push(e.path)
      }
    },
    getindex() {
      console.log('eee')
    }
  }
}
</script>
<style scoped>
.onlnes {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.NavRightSide {
  width: 230px;
  background: #fff;
  margin-right: 40px;
}
.NavRightSide .nav_cont {
}
.NavRightSide .nav_cont .nav_titles {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px 0px 24px;
}
.NavRightSide .nav_cont .nav_titles .titles {
  color: #6b6b6b;
  font-size: 20px;
  font-weight: 300;
}
.NavRightSide .nav_cont .nav_titles img {
  width: 28px;
  height: 28px;
}
.NavRightSide .nav_cont .nav_name {
  height: 118px;
  /* padding: 20px 0px; */
  padding: 20px 24px 0px 24px;
}
.NavRightSide .nav_cont .nav_name .name_ {
  display: flex;
  align-items: center;
}
.NavRightSide .nav_cont .nav_name .name_ .head_img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 5px solid #f6f5f3;
  box-shadow: 0px 4px 12px 0px #edf1f7;
  margin-right: 10px;
}
.NavRightSide .nav_cont .nav_name .name_ right_name_phone {
  display: flex;
  flex-direction: column;
  color: #1f1f1f;
  font-size: 14px;
}

.NavRightSide .nav_cont .nav_name .tag_title {
  margin-top: 20px;
  width: 150px;
  height: 28px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  background: linear-gradient(98deg, #4e494d 0%, #0b0409 100%);
  border-radius: 14px;
  box-shadow: 0px 4px 12px 0px #edf1f7;
}
.NavRightSide .nav_cont .nav_name .tag_title img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.NavRightSide .nav_cont .nav_name .tag_title .ti_center {
  font: 12px/1em 'microsoft yahei';
  color: #fff;
  margin-right: 1em;
}
.NavRightSide .nav_cont .nav_name .tag_title .ti_right {
  color: #ce9b63;
  font: 12px/1em 'microsoft yahei';
  font-weight: bold;
}
.NavRightSide .nav_cont .list_ .nav_list {
  display: inline-block;
  width: 134px;
  height: 70px;
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav_list_ac {
  color: #ce9b63 !important;
}
.NavRightSide .nav_cont .list_ .nav_list .nav_txt {
  font-size: 16px;
  font-weight: 400;
}
.imgs_ac {
  transform: rotate(90deg);
  color: #e8ba8c;
}
.NavRightSide .nav_cont .list_ .list_Children .nav_cont_list {
  height: 70px;
  text-align: center;
  font-size: 16px;
  color: #a5a5a5;
  line-height: 70px;
  display: inline-block;
  width: 229px;
}
.nav_cont_listAC {
  background: #fffaf4;
  box-shadow: 0px 4px 12px 0px #edf1f7;
  color: #ce9b63 !important;
  border-left: 1px solid #ce9b63;
}
</style>

